import React from "react"
import Layout from "../components/layout"

// import Retorno02 from "../images/retorno2.jpeg"
// import Retorno03 from "../images/retorno3.jpeg"

// $('.unit-table').fadeOut(250);
// $('[data-open-table]').on('click', function(){
// 	table = $(this).data('open-table');

// 	$('.unit-table').fadeOut(250);
// 	$('.unit-table').removeClass('selected');
// 	$('[data-open-table]').removeClass('selected');
// 	$(this).addClass('selected');

// 	$('#'+table).fadeIn(250);
// 	$('#'+table).addClass('selected');
// });

// $('.unit-table tbody tr td').each(function(index){
// 	if($.trim($(this).html())==''){
// 		$(this).html('<div className="modality-block"><p>...</p></div>');
// 	}
// });

export default () => (
  <Layout title="Modalidades">
    <div className="modalities-table-wrapper">
      <h1 className="center background-title">Modalidades e Horários</h1>

      <div className="global-inner">
        <div className="row">
          {/* <div className="return-images">
                        <div className="col s12">
                            <img className="responsive-img" src={Retorno02} alt="Retorno"/>
                        </div>
                        <div className="col s12">
                            <img className="responsive-img" src={Retorno03} alt="Retorno"/>
                        </div>
                    </div> */}
          {/* <div className="select-unit col s12">
                        <h5 className="center">Selecione a Unidade</h5>

                        <div className="col s12 select-unit-block" data-open-table="unit-1-table">
                            <div className="title">Unidade 1</div>
                            <div className="description">
                                <p>Rua André de Almeida, 711</p>
                                <p>Jardim Nove de Julho São Paulo - SP</p>
                            </div>
                        </div>
                    </div> */}

          <div className="col s12">
            <table
              id="unit-1-table"
              className="unit-table highlight bordered col s12"
            >
              <thead>
                <tr>
                  <th data-field="period">Período</th>
                  <th data-field="segunda">Segunda</th>
                  <th data-field="terca">Terça</th>
                  <th data-field="quarta">Quarta</th>
                  <th data-field="quinta">Quinta</th>
                  <th data-field="sexta">Sexta</th>
                  <th data-field="sabado">Sábado</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Manhã</td>
                  <td>
                    <div className="modality-block">
                      <strong>Krav Maga</strong>
                      <p>07:40 HS</p>
                      <p>Prof Eber Melo</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Pilates</strong>
                      <p>09:30 HS</p>
                      <p>Prof Jenifer</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Krav Maga</strong>
                      <p>07:40 HS</p>
                      <p>Prof Eber Melo</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Pilates</strong>
                      <p>09:30 HS</p>
                      <p>Prof Jenifer</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Krav Maga</strong>
                      <p>07:40 HS</p>
                      <p>Prof Eber Melo</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Krav Maga</strong>
                      <p>09:00 HS</p>
                      <p>Prof Eber Melo</p>
                    </div>
                    <div className="modality-block">
                      <strong>Fit Dance</strong>
                      <p>11:30 HS</p>
                      <p>Prof Alex Vitorato</p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Tarde</td>
                  <td>
                    <div className="modality-block">
                      <p>...</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Fit Dance</strong>
                      <p>15:00 HS</p>
                      <p>Prof Muller Lima</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <p>...</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Fit Dance</strong>
                      <p>15:00 HS</p>
                      <p>Prof Muller Lima</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Fit Dance</strong>
                      <p>17:00 HS</p>
                      <p>Prof Alex Vitorato</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <p>...</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Noite</td>

                  <td>
                    <div className="modality-block">
                      <strong>Taekwondo</strong>
                      <p>18:30 HS</p>
                      <p>Prof Yasmin</p>
                    </div>
                    <div className="modality-block">
                      <strong>Jiu-Jitsu</strong>
                      <p>20:00 HS</p>
                      <p>Prof Renato Cyborg</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Fit Dance</strong>
                      <p>19:00 HS</p>
                      <p>Prof Alex Vitorato</p>
                      <p>
                        <strong>(à partir de agosto)</strong>
                      </p>
                    </div>
                    <div className="modality-block">
                      <strong>Muay-Thai</strong>
                      <p>20:00 HS</p>
                      <p>Prof Erick</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Taekwondo</strong>
                      <p>18:30 HS</p>
                      <p>Prof Yasmin</p>
                    </div>
                    <div className="modality-block">
                      <strong>Jiu-Jitsu</strong>
                      <p>20:00 HS</p>
                      <p>Prof Renato Cyborg</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Muay-Thai</strong>
                      <p>20:00 HS</p>
                      <p>Prof Erick</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <strong>Taekwondo</strong>
                      <p>18:30 HS</p>
                      <p>Prof Yasmin</p>
                    </div>
                  </td>

                  <td>
                    <div className="modality-block">
                      <p>...</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
